import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import * as medicationAPI from 'api/medication';

const initialState = {
    list: {
        error: false,
        loaded: false,
        data: []
    },
    patientMetrics: {
        error: false,
        loaded: false,
        data: {}
    },
    historical: {
        error: false,
        loaded: false,
        data: []
    },
    details: {
        error: false,
        loaded: false,
        medication: {},
        refillWorkflow: {},
        pdcMetrics: {},
        taskIds: []
    },
    clinicMetrics: {
        error: false,
        loaded: false,
        data: []
    }
};

const slice = createSlice({
    name: 'medication',
    initialState,
    reducers: {
        setMedicationListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setMedicationList(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetListState(state) {
            state.list = initialState.list;
        },

        setMedicationPatientMetricsError(state) {
            state.patientMetrics.error = true;
            state.patientMetrics.loaded = true;
        },
        setMedicationPatientMetrics(state, action) {
            state.patientMetrics.error = false;
            state.patientMetrics.loaded = true;
            state.patientMetrics.data = action.payload;
        },
        resetPatientMetricsState(state) {
            state.patientMetrics = initialState.patientMetrics;
        },

        setMedicationHistoricalError(state) {
            state.historical.error = true;
            state.historical.loaded = true;
        },
        setMedicationHistorical(state, action) {
            state.historical.error = false;
            state.historical.loaded = true;
            state.historical.data = action.payload;
        },
        resetHistoricalState(state) {
            state.historical = initialState.historical;
        },

        setMedicationDetailsError(state) {
            state.details.error = true;
            state.details.loaded = true;
        },
        setMedicationDetails(state, action) {
            state.details.error = false;
            state.details.loaded = true;
            state.details.medication = action.payload.medication;
            state.details.refillWorkflow = action.payload.refillWorkflow;
            state.details.pdcMetrics = action.payload.pdcMetrics;
            state.details.taskIds = action.payload.taskIds;
        },
        resetDetailsState(state) {
            state.details = initialState.details;
        },
        setClinicMetricsError(state) {
            state.clinicMetrics.error = true;
            state.clinicMetrics.loaded = true;
        },
        setClinicMetrics(state, action) {
            state.clinicMetrics.error = false;
            state.clinicMetrics.loaded = true;
            state.clinicMetrics.data = action.payload;
        },
        resetClinicMetricsState(state) {
            state.clinicMetrics = initialState.clinicMetrics;
        }
    }
});

export default slice.reducer;

export function resetListState() {
    dispatch(slice.actions.resetListState());
}

export function resetDetailsState() {
    dispatch(slice.actions.resetDetailsState());
}

export function resetHistoricalState() {
    dispatch(slice.actions.resetHistoricalState());
}

export function resetPatientMetricsState() {
    dispatch(slice.actions.resetPatientMetricsState());
}

export function resetClinicMetricsState() {
    dispatch(slice.actions.resetClinicMetricsState());
}

export function getMedicationList(patientId) {
    return async () => {
        try {
            const response = await medicationAPI.getMedicationList(patientId);
            dispatch(slice.actions.setMedicationList(response.data.medicationList));
        } catch (error) {
            dispatch(slice.actions.setMedicationListError(error));
        }
    };
}

export function getMedicationDetails(patientId, medicationId) {
    return async () => {
        try {
            const response = await medicationAPI.getMedication(patientId, medicationId);
            dispatch(slice.actions.setMedicationDetails(response.data));
        } catch (error) {
            dispatch(slice.actions.setMedicationDetailsError(error));
        }
    };
}

export function getHistoricalData(patientId, medicationId) {
    return async () => {
        try {
            const response = await medicationAPI.getMedicationHistorical(patientId, medicationId);
            dispatch(slice.actions.setMedicationHistorical(response.data.historical));
        } catch (error) {
            dispatch(slice.actions.setMedicationHistoricalError(error));
        }
    };
}

export function getPatientMedicationMetrics(patientId) {
    return async () => {
        try {
            const response = await medicationAPI.getPatientMedicationMetrics(patientId);
            dispatch(slice.actions.setMedicationPatientMetrics(response.data.metrics));
        } catch (error) {
            dispatch(slice.actions.setMedicationPatientMetricsError(error));
        }
    };
}

export function getClinicMetrics(clinicId) {
    return async () => {
        try {
            const response = await medicationAPI.getClinicMedicationMetrics(clinicId);
            dispatch(slice.actions.setClinicMetrics(response.data.metrics));
        } catch (error) {
            dispatch(slice.actions.setClinicMetricsError(error));
        }
    };
}
